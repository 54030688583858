import React, { lazy, Suspense, useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Loader, Footer, Header, ErrorPage } from '@gogeepernpm/storybook/lib';
//@ts-ignore
import AOS from 'aos';
import { useSelector } from 'react-redux';
import { IRootReducerState } from './redux/IRootReducer';
import { useGlobalAuth } from './hooks/useGlobalAuth';

const Homepage = lazy(() => import('./pages'));
const Blog = lazy(() => import('./pages/blog'));

function App() {
  const { isInitialized } = useSelector(
    (state: IRootReducerState) => state.auth
  );

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  const [open, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleItemClick = (index: number) =>
    activeIndex === index ? setActiveIndex(-1) : setActiveIndex(index);
  const toggleSidebar = () => setOpen(!open);

  const contact = () => window.Tawk_API.maximize();
  useEffect(() => {}, [isInitialized]);

  const { triggerLogout, triggerSignUp, triggerlogin } = useGlobalAuth();

  const { isAuthenticated } = useSelector(
    (state: IRootReducerState) => state.auth
  );

  return (
    <div className='App'>
      <Suspense fallback={<Loader loading={true} />}>
        <Header
          sidebarOpen={open}
          toggleSidebarOpen={toggleSidebar}
          activeIndex={activeIndex}
          onClick={handleItemClick}
          onTalkToUsClick={contact}
          isLoggedIn={isAuthenticated}
          triggerLogin={triggerlogin}
          triggerSignup={triggerSignUp}
          triggerLogout={triggerLogout}
        />

        <Router>
          <Routes>
            <Route path='/' element={<Homepage />} />
            <Route path='/blog/:id' element={<Blog />} />
            <Route
              path='*'
              element={
                <ErrorPage onContact={contact} onSignUp={triggerSignUp} />
              }
            />
          </Routes>
        </Router>
        <Footer onTalkToUsClick={contact} />
      </Suspense>
    </div>
  );
}

export default App;
